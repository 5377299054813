import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute ,NavigationStart} from '@angular/router';
import { filter } from 'rxjs/operators';
import { HeaderService } from '../../header/Header/header.service';
import metaData from 'src/assets/data/default/header-footer.json';
import metaData_vimpro from 'src/assets/data/vimpro/header-footer.json';
import metaData_schift from 'src/assets/data/schift/header-footer.json';
import { config }  from 'src/configs/config';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { UntypedFormGroup ,FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AlertsService } from 'src/app/Components/alerts/alerts.service';
import { ProductListingService } from 'src/app/Lazy-loaded-Modules/Product-Modules/product-lists/product-listing/product-listing.service';
import { OrderSummaryInitialService } from 'src/app/Lazy-loaded-Modules/Checkout-new/order-summary/order-summary-initial.service';


import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-two-footer',
  templateUrl: './footer-two.component.html'
})
export class FooterTwoComponent implements OnInit {
  subscribeForm: FormGroup;
  formBuilder: any;
  footerService: any;
  logoImage : string;
  validateForm: boolean = false;
  showalertmsg: boolean = false;
  routeurl;
  preList=[];
  //appService: any;
  // alertService: any;
  windowscroll()
  {
    window.scrollTo(0,0)
  }
  config=config;

  copyRightText:any = "";

  onLineShopping = metaData.footer;
  onLineShopping_vimpro = metaData_vimpro.footer;
  onLineShopping_schift = metaData_schift.footer;
  
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private localService: LocalStorageService,
    private alertService: AlertsService,
    private  productListingService:ProductListingService,

    private formbuilder: FormBuilder,
     private appService : AppService
  ) { }

  ngOnInit(): void {
    // this.getCopyRightText();
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: any) => {
     console.log(event.url,"event.url")
     this.routeurl=event.url; 
    })

    this.subscribeForm = new FormGroup({
      email: this.formbuilder.control('', [Validators.required, Validators.maxLength(50), Validators.email, Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$")]),
    })
    this.subscribeForm.controls.email.setValue('');
    // this.footerService.currentData.subscribe(
    //   currentData => {
    //     if (currentData === 'clearSearchTerm') {
    //       this.subscribeForm.controls.email.setValue('');
    //       this.validateForm=false;
    //     }
    //   }
    // )
    this.getLogo();
  }
  get f() { return this.subscribeForm.controls; }
  subscribe() {
    this.showalertmsg = true;
    if (this.subscribeForm.valid) {
      this.validateForm = false;
    var subscribeFormData = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: ORG_DETAILS.USER_ID,
      "payload": {
        "customerSubcribe": {
          "has_also_subscribed": true,
          "email": this.subscribeForm.value.email
        }
      },
      "extras": {
        "find": {
          "email": this.subscribeForm.value.email
        }
      }

    }
console.log(subscribeFormData)
    this.appService.postApiCall(subscribeFormData, ENDPOINTS.SUBSCRIBE).subscribe(resp => {
      if (resp) {
        if (resp.success == 1) {
          window.scroll(0,0);
          this.alertService.success('Subscribed Succesfully!', this.options);
         
          this.subscribeForm.reset();
          
        }
        else {
          this.alertService.warn(resp.message, this.options);
        }
      }
    },
      err => {
        if (err.error.message) {
          this.alertService.error(err.error.message, this.options);
        }
        else {
          this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
    // }
    // if (this.subscribeForm.valid) {
    //   //this.validateForm = false;

      
    }
    else {
      this.validateForm = true;
    }

  }
  // options(arg0: string, options: any) {
  //   throw new Error('Method not implemented.');
  // }

  navigateToProduct(link){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    console.log("link",link)
    window.scrollTo({top: 0, behavior: 'smooth'});
    // this.router.navigate([`/productListing`], {queryParams: { terms:link, type:'filter' }, relativeTo:this.route,  skipLocationChange: false });
   if(config.title==='BombayHardware' || config.title==='AvonSeals'){
    this.localService.set('allProducts','notall')
    if(link == '/Pipes'){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],['Pipes'],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	
    }
    if(link == '/Supreme Pipes & Fittings'){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],['Supreme Pipes and Fittings'],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	
    }
   } else if(config.title === "BBold"){
    this.router.navigate([`/product`], {queryParams: { }, relativeTo:this.route,  skipLocationChange: false });
  } else if(config.title === "Cle"){
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	
  } else {
    this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

   }

   
    // if(link==='/productListing') {
    //   this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

    // } 
    // if(link==='/category-listing'){
    //   this.router.navigate([`/category-listing`]);

    // }

  }

  scrollFunction(){
    window.scrollTo({top: 0, behavior: 'smooth'});

  }
  getBrochure(){
    //this.headerService.updateAdminFormEvent('clearSearchTerm');
    window.open("http://d1bo46l1lnh354.cloudfront.net/Mahaveer/Brochure/ITR-Lubricants-Brochure.pdf")
  }
  productDetails(id) {
 
    this.router.navigate(["/product-details", id], {
      relativeTo: this.route,
      skipLocationChange: false,
    });
  }
  getProducts(){
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine'}, relativeTo:this.route,  skipLocationChange: false });

  }
  getLatestProducts(){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if( config.title==='Beaubelle' || config.title==='AvonSeals' ){
      const slides = document.getElementsByClassName('slide');
      // this.router.navigate([`/category-listing`], {queryParams: {}, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      console.log("check if this is called")
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
     }
     if(config.title==='BombayHardware' ){
      console.log("1")
      // this.productListingService.updateAdminFormEvent("fromKnowMore");
      if(this.router.url=='/productListing?terms=&terms=&terms=&terms=&terms=&terms=&terms=&terms=&terms=&terms=&terms=&terms=&type=combine'){
        window.scroll(0,0)
        let element: HTMLElement = document.getElementsByClassName('show-for-sr')[0] as HTMLElement;
      element.click()

      }
      else{
        console.log("2")
        this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine'}, relativeTo:this.route,  skipLocationChange: false });
       this.productListingService.updateAdminFormEvent({name: "fromKnowMore"});

      }
      

      

}
     
    
     
// if(config.title==='Accel') {
//   this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
//  }
     
      else {
      this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
     }
  }

  // getCopyRightText(){
  //   // REQUEST DATA
  //   let request_data = {
  //     "domain_name": "www.mahaveerdistributor.in",
  //     "user_id": 17,
  //     "extras": {
  //         "find": {}
  //     }
  //   }
  //   // MAKE AN API CALL
  //   this.appService.postApiCall(request_data, ENDPOINTS.STORE_SETTINGS).subscribe(
  //     resp => {
  //       let respData = resp.result.data[0];
  //       if(respData.website){
  //         this.copyRightText = respData.website.copyright_text;
  //       }
  //     },
  //     err => {
  //       if (err.error.error.message) {
  //         console.log(err.error.error.message)
  //       }
  //       else{
  //         console.log('Something bad happened; Please try again!')
  //       }
  //     })
  // }
  navigateToCategory(name){
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    this.router.navigate(['/']).then(()=>
   // this.router.navigate([`/productListing`], { queryParams: { terms: [name], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false }));
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false }));
   this.router.navigate([`/productListing`], {queryParams: { terms:[[name],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false }));


  }
   navigateToList(category) {
    
      this.localService.set('allProducts','notall')
      console.log("insideee")
      // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }
  getLogo(){
    let data = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
  }
  this.appService.postApiCall(data, ENDPOINTS.STOREGALLERY).subscribe(
    resp => {
      let respData = resp.result.data[0];
      this.logoImage = respData.logo.logo_image_source;
    },
    err => {
      if (err) {
        console.log("Unable to obtain logo \n, ERROR:", err.message);
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
  }
  showOrder()
  {
    console.log("userIdddddddd")
    let userId = this.localService.get('UserId');
    if(userId){
      console.log("userId"+userId)
      window.scroll(0,0)
      // this.router.navigate(['/my-orders'])
      this.router.navigate(['/my-orders'], { queryParams: { url:'contact-us' },relativeTo:this.route,  skipLocationChange: false })

    } else {
      this.router.navigate(['/login-new'], { relativeTo:this.route,  skipLocationChange: false })
    }
  }
  openBranchLocation() {
    if(config.title==='AvonSeals'){

        window.open("https://www.google.com/maps/place/Avon+Seals+Pvt+Ltd/@13.0902839,80.1740384,15z/data=!4m6!3m5!1s0x3a52639655555555:0x7e2c7e0121a73915!8m2!3d13.0902839!4d80.1740384!16s%2Fg%2F1tf_r9j3?entry=ttu", '_blank');
      }}
      specialBranchLocation() {
        if(config.title==='SpecialWires'){
        window.open("https://www.google.com/maps/place/Special+Wire+Products+(P)+Ltd/@13.111962,80.1669762,17z/data=!4m10!1m2!2m1!1sspecialwires!3m6!1s0x3a52639a1d9e7843:0x81570f0249437a8!8m2!3d13.113432!4d80.170832!15sCgxzcGVjaWFsd2lyZXOSAQxtYW51ZmFjdHVyZXLgAQA!16s%2Fg%2F11bw20jfr7?entry=ttu", '_blank');
      }
      if(config.title==='Vistas'){
        window.open("https://www.google.com/maps/place/Alankar+enclave/@13.0432549,80.156055,17z/data=!4m10!1m2!2m1!1sG1,+New+NO.1%2F182,+Alankar+Enclave,+Alanlarnagar+2nd+street+Sheik+Maniyar,+Vanagaram,Chennai,+Tamil+Nadu,+600095!3m6!1s0x3a5261d66b6c6d83:0x29b5a73589433044!8m2!3d13.0432497!4d80.1586299!15sCm9HMSwgTmV3IE5PLjEvMTgyLCBBbGFua2FyIEVuY2xhdmUsIEFsYW5sYXJuYWdhciAybmQgc3RyZWV0IFNoZWlrIE1hbml5YXIsIFZhbmFnYXJhbSxDaGVubmFpLCBUYW1pbCBOYWR1LCA2MDAwOTWSARJhcGFydG1lbnRfYnVpbGRpbmfgAQA!16s%2Fg%2F11qgk8k5z3?entry=ttu", '_blank');
      }
    }
    nav_accel_cate1(){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],["Long Term Courses"],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    }
    nav_accel_cate2(){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],["Short Term Courses"],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    }


    sendProductDetails1(searchTerm){
      console.log("searchTerm"+searchTerm)
      let searchRequestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
            "search": searchTerm
          },
          "pagination": true,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 35
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "id",
            "sortorder": 1
          }
        }
      }
      // MAKE AN API CALL
       this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
        resp => {
          let respData = resp.result.data;
          this.preList = [];
          //DIRECT TO PRODUCT DETAILS IF ID PRESENT
          console.log("respData.length1"+respData.length)
          if(respData.length === 1){
            console.log("respData.length2"+respData.length)
            //this.searchForm.controls['search_term'].setValue(searchTerm.name);
            this.router.navigate(['/']).then(()=>
            {
              if(this.config.title === "Aiema" ||this.config.title === "Cle"){
                this.router.navigate([`/company-details1/${respData[0].id}`]);
              }
              else{
                console.log("respData.length3"+respData.length)
                this.router.navigate([`/product-details/${respData[0].id}`]);
              }
              
            })
          }
        
          
                 
              // if(this.configs.title === "BBold"){
              //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
              // } 
             
            })
          }

    }

